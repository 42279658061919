import React, { useState } from "react"
import * as style from "./PerkCard.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { formatLongDate, getGatsbyImage } from "../../plugins/common"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import Icon from "@mdi/react"
import { mdiChevronUp } from "@mdi/js"

const PerkCard = ({ data, labels, lang }) => {
  const { title, image, content, additionalInfo, expiryDate } = data
  const { expiryDateLabel } = labels
  const expiry = formatLongDate(expiryDate, lang, "short")
  const [opened, setOpened] = useState(false)
  return (
    <div
      className={`soft-shadows ${style.perkCard} corners position-relative `}
    >
      <div className={`position-relative ${style.imageWrapper}`}>
        <GatsbyImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          className={`${style.image}`}
          image={getGatsbyImage(image)}
          alt="alea"
        />
      </div>
      <div
        className={`px-3 py-4 ${style.content} ${
          opened ? style.opening : ""
        } bg-white flex flex-column`}
      >
        <div className={`ming lora-SemiBold ${style.title}`}>{title}</div>
        <div className={`my-3`}>
          <CustomMarkdown html={content} color="copper" />
        </div>
        {additionalInfo && (
          <div className={`my-3 ${style.additionalInfo}`}>
            <CustomMarkdown html={additionalInfo} color="copper" />
          </div>
        )}
        {expiryDate && (
          <div className={`teal openSans-SemiBold ${style.bottomText}`}>
            {expiryDateLabel.split("{date}").join(expiry)}
          </div>
        )}
      </div>
      <div
        role="button"
        tabIndex={0}
        className={`d-lg-none d-block text-center py-3 `}
        onKeyDown={() => setOpened(!opened)}
        onClick={() => setOpened(!opened)}
      >
        <Icon
          path={mdiChevronUp}
          size={1.5}
          color={`ming`}
          className={`${style.arrow}  ${opened ? "" : `${style.arrowDown}`}`}
        />
      </div>
    </div>
  )
}

export default PerkCard
