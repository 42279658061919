import React, { useState, useEffect } from "react"
import * as style from "./member-perks.module.scss"
import loadable from "@loadable/component"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getBackgroundImage,
  getCurrentLanguagePage,
} from "../plugins/common"
import Header from "../components/member-perks/Header"
import { CustomMarkdown } from "../plugins/sanitizehtml"
import Dash from "../components/Dash"
import PerkCard from "../components/member-perks/PerkCard"
import Button from "../components/Button"
import { StaticImage } from "gatsby-plugin-image"

const Banner = loadable(() => import("../components/Banner"))

const MemberPerksPage = ({ pageContext, location }) => {
  // console.log("pageContext : ", pageContext)
  const bodyLeftShape = "../images/member-perks/body-left.png"
  const bodyRightShape = "../images/member-perks/body-right.png"

  const { data, general, form } = pageContext
  const aleaToken = general.aleaToken
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(data.header.image)
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }
  const { showMoreLabel } = general.allStrapiGeneral?.nodes[0]?.commonLabels
  const labels = {
    expiryDateLabel: data.expiryDateLabel,
  }

  //LARGE SCREEN HAS 4 CARDS A ROW
  const [listLimit, setListLimit] = useState(6)
  const [isLarge, setIsLarge] = useState(false)

  const loadMore = () => setListLimit(listLimit + 6)

  useEffect(() => {
    const checkSize = () => {
      const screenWidth = document.body.clientWidth
      // console.log("screenWidth ", screenWidth)
      if (screenWidth <= 1440 && isLarge) {
        setIsLarge(false)
        setListLimit(parseInt(listLimit / 8) * 6)
      } else if (screenWidth > 1440 && !isLarge) {
        setIsLarge(true)
        setListLimit(parseInt(listLimit / 6) * 8)
      }
    }
    window.addEventListener("resize", checkSize)
    return () => {
      window.removeEventListener("resize", checkSize)
    }
  }, [isLarge, listLimit])

  useEffect(() => {
    if (document.body.clientWidth > 1440) {
      setIsLarge(true)
      setListLimit(8)
    }
  }, [])
  return (
    <Layout
      props={pageContext.general}
      meta={data.meta}
      localizations={getAllLocales(data.localizations, data.locale)}
      location={location}
      lang={lang}
      ogImage={ogImage}
      hideNewsletter={true}
      noindex={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <Header data={data.header} />
      <div className={`${style.perkListIntro} mx-auto mt-5`}>
        <h2 className={`text-center ming`}>
          <CustomMarkdown html={data.introTitle} color="copper" />
        </h2>
        <Dash colorClass={`bg-teal`} align={`text-center`} />
        <div className={`text-center mt-4`}>{data.introDesc}</div>
      </div>
      <div className={`${style.perkListWrapper}  position-relative`}>
        <div className={`${style.perkList} d-grid mx-auto my-5`}>
          {data?.perks.slice(0, listLimit).map((p, index) => (
            <div key={index} className={`${style.perkCard}`}>
              <PerkCard data={p} labels={labels} lang={lang} />
            </div>
          ))}
        </div>

        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          className={`${style.bodyLeftShape} position-absolute`}
          src={bodyLeftShape}
          alt="alea"
        />
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          className={`${style.bodyRightShape} position-absolute`}
          src={bodyRightShape}
          alt="alea"
        />
      </div>
      {listLimit < data?.perks.length && (
        <div className={`my-5 text-center`}>
          <Button
            lang={lang}
            type={`secondary`}
            text={showMoreLabel}
            event={loadMore}
          />
        </div>
      )}
      <Banner props={data.banner} lang={lang} />
    </Layout>
  )
}
export default MemberPerksPage
