// extracted by mini-css-extract-plugin
export var additionalInfo = "PerkCard-module--additionalInfo--enk7N";
export var alertRed = "PerkCard-module--alert-red--U6Wkl";
export var arrow = "PerkCard-module--arrow--H83Uc";
export var arrowDown = "PerkCard-module--arrowDown--Z0nIl";
export var bgBarGrey = "PerkCard-module--bg-bar-grey--8zwQj";
export var bgBeige = "PerkCard-module--bg-beige--+4+m4";
export var bgCopper = "PerkCard-module--bg-copper--0Ncre";
export var bgDaffodil = "PerkCard-module--bg-daffodil--drk2v";
export var bgGreyText = "PerkCard-module--bg-grey-text--CgSOs";
export var bgMing = "PerkCard-module--bg-ming--daNcu";
export var bgMint = "PerkCard-module--bg-mint--7Y-I9";
export var bgRed = "PerkCard-module--bg-red--WlQr8";
export var bgRedTrans = "PerkCard-module--bg-red-trans--qB7hO";
export var bgSand = "PerkCard-module--bg-sand--4QfWF";
export var bgSoftSun = "PerkCard-module--bg-softSun--T1E0B";
export var bgTeal = "PerkCard-module--bg-teal--0u7ay";
export var bgWhite = "PerkCard-module--bg-white--BsTNh";
export var bgWomenSharp = "PerkCard-module--bg-women-sharp--bMGE3";
export var blackText = "PerkCard-module--black-text--ZqO9i";
export var bottomText = "PerkCard-module--bottomText--1MQ76";
export var content = "PerkCard-module--content--tcniN";
export var copper = "PerkCard-module--copper--n24px";
export var corners = "PerkCard-module--corners--QYLXN";
export var cursorNormal = "PerkCard-module--cursor-normal--x-nji";
export var cursorPointer = "PerkCard-module--cursor-pointer--scVMz";
export var dUnset = "PerkCard-module--d-unset--1H-CG";
export var darkGrey = "PerkCard-module--dark-grey--Fl2fy";
export var dash = "PerkCard-module--dash--nsytn";
export var flex = "PerkCard-module--flex--dga+R";
export var flex1 = "PerkCard-module--flex-1--R4mJj";
export var fontSize12 = "PerkCard-module--font-size-12--Pjw04";
export var fontSize20 = "PerkCard-module--font-size-20--TF26T";
export var greyPlaceholder = "PerkCard-module--grey-placeholder---YmTt";
export var greyText = "PerkCard-module--grey-text--UROmX";
export var h1sizing = "PerkCard-module--h1sizing--7AD+J";
export var h2sizing = "PerkCard-module--h2sizing--STHz4";
export var hidden = "PerkCard-module--hidden--bi6YT";
export var image = "PerkCard-module--image--OfSCx";
export var imageAni = "PerkCard-module--imageAni--W82HV";
export var imageWrapper = "PerkCard-module--imageWrapper--lmnrC";
export var imageZoom = "PerkCard-module--imageZoom--m3lKF";
export var imageZoomGatsby = "PerkCard-module--imageZoomGatsby--Y950z";
export var italic = "PerkCard-module--italic--1pwK0";
export var lightGrey = "PerkCard-module--light-grey--r1Qcf";
export var logo = "PerkCard-module--logo--zOiZg";
export var lora = "PerkCard-module--lora--20Aim";
export var loraBold = "PerkCard-module--lora-Bold--XLt0W";
export var loraBoldItalic = "PerkCard-module--lora-BoldItalic--MVHxE";
export var loraMedium = "PerkCard-module--lora-Medium--eUEG9";
export var loraSemiBold = "PerkCard-module--lora-SemiBold--PR6xT";
export var main = "PerkCard-module--main--Ye283";
export var ming = "PerkCard-module--ming--73uPC";
export var mingHover2 = "PerkCard-module--ming-hover-2--yfKUZ";
export var modalOpen = "PerkCard-module--modal-open--yGpxr";
export var noUnderline = "PerkCard-module--no-underline--EWwSM";
export var openSans = "PerkCard-module--openSans--X1en6";
export var openSansBold = "PerkCard-module--openSans-Bold--7mgkb";
export var openSansSemiBold = "PerkCard-module--openSans-SemiBold--3Wgvj";
export var opening = "PerkCard-module--opening--Mj5mz";
export var perkCard = "PerkCard-module--perkCard--u0tkv";
export var quotation = "PerkCard-module--quotation--omQ5Q";
export var round = "PerkCard-module--round--S9DSM";
export var roundCorners = "PerkCard-module--round-corners--+v27b";
export var softCorners = "PerkCard-module--soft-corners--X3a2X";
export var softShadows = "PerkCard-module--soft-shadows--wI1dL";
export var softerCorners = "PerkCard-module--softer-corners--vFHld";
export var softyCorners = "PerkCard-module--softy-corners--f1S8g";
export var tableShadow = "PerkCard-module--table-shadow--+vOeb";
export var teal = "PerkCard-module--teal--q2geW";
export var title = "PerkCard-module--title--nZjAb";
export var topNavShadow = "PerkCard-module--top-nav-shadow--515YA";
export var uppercase = "PerkCard-module--uppercase--RCcUW";
export var wFull = "PerkCard-module--w-full--dayO3";
export var white = "PerkCard-module--white--yx+5m";
export var womenRed = "PerkCard-module--women-red--zJbA3";
export var womenSharp = "PerkCard-module--women-sharp--d9ao2";