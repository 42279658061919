import React from "react"
import * as style from "./Header.module.scss"
import bg from "../../images/member-perks/bg.png"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { getGatsbyImage } from "../../plugins/common"

const Header = ({ data }) => {
  const headerLeftEmpty = "../../images/member-perks/left-line.png"
  const headerLeftFull = "../../images/member-perks/left-full.png"
  const headerRightEmpty = "../../images/member-perks/right-line.png"
  const headerRightFull = "../../images/member-perks/right-full.png"
  const { title, description, image } = data
  return (
    <div
      className={`${style.header} position-relative d-grid`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={`${style.leftShapes} position-relative d-none d-lg-grid`}>
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          className={`${style.leftFull} position-absolute`}
          src={headerLeftFull}
          alt="alea"
        />
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          className={`${style.leftEmpty} position-absolute`}
          src={headerLeftEmpty}
          alt="alea"
        />
      </div>
      <div className={`${style.content} align-self-center mx-lg-0 mx-5`}>
        <h1 className={`ming`}>{title}</h1>
        <div className={`ming openSans ${style.desc}`}>{description}</div>
      </div>
      <div className={`${style.imageWrapper} imageZoom position-relative`}>
        <GatsbyImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          className={`${style.image} imageAni position-absolute`}
          image={getGatsbyImage(image)}
          alt="alea"
        />
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          className={`${style.rightFull} position-absolute`}
          src={headerRightFull}
          alt="alea"
        />
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          className={`${style.rightEmpty} position-absolute`}
          src={headerRightEmpty}
          alt="alea"
        />
      </div>
    </div>
  )
}

export default Header
